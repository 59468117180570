<script>
import { useAuthStore } from '@/stores/auth'

export default {
  name: 'ShopMixin',
  data () {
    return {
      user_shop: null,
      shop_url: process.env.VUE_APP_VENDOR_DASHBOARD_URL
    }
  },
  computed: {
    isShopActive: function (){
      return this.user_shop !== null && this.user_shop.is_active
    }
  },
  methods: {
    $_getShop: async function () {
      if (localStorage.getItem('token') !== null) {
        const authStore = useAuthStore()
        this.user_shop = await authStore.getShop()
      }
    }
  }
}
</script>

<style scoped>

</style>
