<template>
  <section class="product-tabs section-padding position-relative">
    <div class="container">
      <div class="section-title style-2 wow animate__animated animate__fadeIn">
        <h3>Scopri i prodotti dei negozietti</h3>
        <ul class="nav nav-tabs links" id="myTab" role="tablist">
          <li v-for="(category, index) in categories.getCategories"
              :key="index" class="nav-item" role="presentation">
            <button :class="'nav-link ' + [index === 0 ? 'active' : '']" :id="'nav-tab-' + index" data-bs-toggle="tab"
                    :data-bs-target="'#tab-' + index"
                    type="button" role="tab" :aria-controls="'tab-' + index" aria-selected="false">
              {{ category.name }}
            </button>
          </li>
        </ul>
      </div>
      <div class="tab-content" id="myTabContent">
        <div v-for="(category, index) in categories.getCategories"
             :key="index" :class="'tab-pane fade ' + [index === 0 ? 'show active' : '']" :id="'tab-' + index"
             role="tabpanel"
             :aria-labelledby="'tab-' + index">
          <div class="row product-grid-4">
            <div class="col-lg-1-5 col-md-4 col-12 col-sm-6" v-if="category.latest_products.length === 0">
              <p>Hai qualche prodotto da vendere per questa categoria? <a href="/#/become-vendor">Apri un negozio adesso</a></p>
            </div>

            <div class="col-lg-1-5 col-md-4 col-12 col-sm-6" v-for="(product,key) in category.latest_products"
                 :key="key">
              <ProductCardComponent v-if="product.shop.stripe_account_id !== null"
                                    :product="product"></ProductCardComponent>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { useCategoryStore } from '@/stores/categories'
import ProductCardComponent from '@/components/ProductCardComponent'

export default {
  name: 'LatestProducts',
  components: { ProductCardComponent, },
  setup () {
    const categories = useCategoryStore()
    return { categories }
  },
}
</script>

<style scoped>

</style>
