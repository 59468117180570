<template>
  <EcommerceLayout>
    <template v-slot:content>
      <section class="home-slider position-relative mb-30">
        <div class="container">
          <div class="home-slide-cover mt-30">
            <div class="hero-slider-1 style-4 dot-style-1 dot-style-1-position-1">
              <div class="single-hero-slider single-animation-wrap"
                   style="background-image: url('https://ucarecdn.com/3099b0af-2494-4790-8411-2682afa42265/')">
                <div class="slider-content">
                  <h1 class="display-2 mb-40" style="color: white;">
                    Vendi su<br/>
                    Negozietto
                  </h1>
                  <p class="mb-65" style="color: white;">Contattaci per maggiori informazioni</p>
                  <a class="btn" href="/#/contact-us">Contattaci</a>
                </div>
              </div>
              <div class="single-hero-slider single-animation-wrap"
                   style="background-image: url('https://ucarecdn.com/f4361b1a-3f03-4ff5-bc7e-ca851f779205/')">
                <div class="slider-content">
                  <h1 class="display-2 mb-40" style="color: white; font-size: 50px; text-shadow: 1px 1px 1px black; background-color: #F1A208; vertical-align: middle;">
                    "Esplora l'eleganza senza tempo del vintage"<br/>
                  </h1>
                  <a class="btn" :href="'/#/categories/16'">Scopri</a>
                </div>
              </div>
              <div class="single-hero-slider single-animation-wrap"
                   style="background-image: url('https://ucarecdn.com/62ab6479-b835-48c8-b47f-5458aaab3c58/')">
                <div class="slider-content">
                  <h1 class="display-2 mb-40" style="color: white; font-size: 50px; text-shadow: 1px 1px 1px black; background-color: #F1A208; vertical-align: middle;">
                    "Sapori deliziosi"<br/>
                  </h1>
                  <a class="btn" :href="'/#/categories/13'">Scopri</a>
                </div>
              </div>
              <div class="single-hero-slider single-animation-wrap"
                   style="background-image: url('https://ucarecdn.com/35d10bb0-77ba-4939-9aaf-a700c0b45432/')">
                <div class="slider-content">
                  <h1 class="display-2 mb-40" style="color: white; font-size: 50px; text-shadow: 1px 1px 1px black; background-color: #F1A208; vertical-align: middle;">
                    "Trova il tuo stile unico"<br/>
                  </h1>
                  <a class="btn" :href="'/#/categories/11'">Scopri</a>
                </div>
              </div>
              <div class="single-hero-slider single-animation-wrap"
                   style="background-image: url('https://ucarecdn.com/76262740-b008-41ec-9c0e-23b905253a3f/')">
                <div class="slider-content">
                  <h1 class="display-2 mb-40" style="color: white; font-size: 50px; text-shadow: 1px 1px 1px black; background-color: #F1A208; vertical-align: middle;">
                    "Vedila chiara con i nostri occhiali di alta qualità"<br/>
                  </h1>
                  <a class="btn" :href="'/#/categories/19'">Scopri</a>
                </div>
              </div>
              <div class="single-hero-slider single-animation-wrap"
                   style="background-image: url('https://ucarecdn.com/56ca5966-5821-4815-8caa-e5ed6a712f92/')">
                <div class="slider-content">
                  <h1 class="display-2 mb-40" style="color: white; font-size: 50px; text-shadow: 1px 1px 1px black; background-color: #F1A208; vertical-align: middle;">
                    "Tutto ciò di cui hai bisogno per il tuo progetto elettrico"<br/>
                  </h1>
                  <a class="btn" :href="'/#/categories/14'">Scopri</a>
                </div>
              </div>
              <div class="single-hero-slider single-animation-wrap"
                   style="background-image: url('https://ucarecdn.com/e60e0552-5d8f-4cf6-aa38-c7bd5b027230/')">
                <div class="slider-content">
                  <h1 class="display-2 mb-40" style="color: white; font-size: 50px; text-shadow: 1px 1px 1px black; background-color: #F1A208; vertical-align: middle;">
                    "Preparati per la scuola o l'ufficio con i nostri prodotti di alta qualità"<br/>
                  </h1>
                  <a class="btn" :href="'/#/categories/18'">Scopri</a>
                </div>
              </div>
              <div class="single-hero-slider single-animation-wrap"
                   style="background-image: url('https://ucarecdn.com/6c162f4d-ef95-4e5f-a4c7-5e35b65f7009/')">
                <div class="slider-content">
                  <h1 class="display-2 mb-40" style="color: white; font-size: 50px; text-shadow: 1px 1px 1px black; background-color: #F1A208; vertical-align: middle;">
                    "Trova le migliori soluzioni per i tuoi progetti di ferramenta e termoidraulica"<br/>
                  </h1>
                  <a class="btn" :href="'/#/categories/20'">Scopri</a>
                </div>
              </div>
            </div>
            <div class="slider-arrow hero-slider-1-arrow"></div>
          </div>
        </div>
      </section>
      <LatestProducts></LatestProducts>
    </template>
  </EcommerceLayout>
</template>

<script>
// @ is an alias to /src
import EcommerceLayout from '@/layout/ecommerce/EcommerceLayout'
import LatestProducts from '@/components/LatestProducts'
import { useCategoryStore } from '@/stores/categories'

export default {
  name: 'HomeView',
  components: { LatestProducts, EcommerceLayout },
  setup () {
    const categories = useCategoryStore()
    console.log(categories.getCategories)
    return { categories }
  },
}
</script>
