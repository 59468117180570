<template>
  <div>
    <HeaderComponent></HeaderComponent>
    <main class="main">
      <slot name="content"></slot>
    </main>
    <FooterComponent></FooterComponent>
  </div>
</template>

<script>
import HeaderComponent from '@/layout/ecommerce/header/HeaderComponent'
import FooterComponent from '@/layout/ecommerce/FooterComponent'

export default {
  name: 'EcommerceLayout',
  components: { FooterComponent, HeaderComponent },
  created () {
    this.$nextTick(function () {
      setTimeout(function () {
        var po = document.createElement('script')
        po.type = 'text/javascript'
        po.async = true
        po.src = 'assets/js/main.js'
        var s = document.getElementsByTagName('script')[0]
        s.parentNode.insertBefore(po, s)

        var po1 = document.createElement('script')
        po1.type = 'text/javascript'
        po1.async = true
        po1.src = 'assets/js/shop.js'
        var s1 = document.getElementsByTagName('script')[0]
        s1.parentNode.insertBefore(po1, s1)
      }, 550)
    })
  }
}
</script>

<style scoped>

</style>
