<script>
import { useCartStore } from '@/stores/cart'

export default {
  name: 'CartMixin',
  computed: {
    cart: function () {
      if (localStorage.getItem('token') !== null) {
        let cart = useCartStore()
        return cart.getCart
      }

      return []
    },
    cartTotal: function () {
      let cart = useCartStore()
      cart = cart.getCart
      return cart.reduce((acc, relation) => {
        return acc + relation.product.price_with_taxes * relation.quantity
      }, 0)
    },
    cartByShop () {
      let cartShop = {}
      let cart = useCartStore()
      cart = cart.getCart
      cart.forEach(function (rel) {
        if (Object.hasOwn(cartShop, rel.product.shop.id)) {
          cartShop[rel.product.shop.id].push(rel)
        } else {
          cartShop[rel.product.shop.id] = [rel]
        }
      })

      return cartShop
    }
  },
  methods: {
    $_cartMixinDelete (product) {
      const cart = useCartStore()
      cart.removeProduct(product)
      cart.updateCart()
      this.cart = cart.getCart
    },
    $_cartMixinAdd (product, quantity = 1) {
      if (localStorage.getItem('token') !== null) {
        const cart = useCartStore()
        if (cart.hasProduct(product.id)) {
          cart.increaseQuantity(product, quantity)
        } else {
          cart.addProduct(product, quantity)
        }

        this.$toast.open({
          message: 'Prodotto aggiunto al carrello 🎉',
          position: 'bottom-right',
          type: 'success',
          duration: 5000,
          dismissible: true
        })
      } else {
        this.$toast.open({
          message: 'Accedi prima di aggiungere al carrello',
          position: 'top-right',
          type: 'error',
          duration: 5000,
          dismissible: true
        })
      }
    },
    $_cartMixinChangeQuantity (product, quantity = 1) {
      const cart = useCartStore()
      cart.changeQuantity(product, quantity)
      cart.updateCart()
      this.cart = cart.getCart
    },
  }
}
</script>
