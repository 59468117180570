<template>
  <div class="product-cart-wrap mb-30 wow animate__animated animate__fadeIn">
    <div class="product-img-action-wrap">
      <div class="product-img product-img-zoom">
        <a :href="'/#/products/' + product.id">
          <img v-if="product.images.length > 0" class="default-img" :src="product.images[0].url" alt=""/>
          <img v-else class="default-img" src="/assets/imgs/shop/product-1-1.jpg" alt=""/>
        </a>
      </div>
    </div>
    <div class="product-content-wrap">
      <div class="product-category">
        <a :href="'/#/products/' + product.id"></a>
      </div>
      <h2><a :href="'/#/products/' + product.id">{{ product.name }}</a></h2>
      <div>
          <span class="font-small text-muted">Da <a
              :href="'/#/vendors/' + product.shop.id">{{ product.shop.business_name }}</a></span>
      </div>
      <div class="product-card-bottom">
        <div class="product-price">
          <span>&euro; {{ product.price_with_taxes }}</span>
        </div>
        <div class="add-cart" @click="$_cartMixinAdd(product)" v-if="product.quantity > 0">
          <a class="add"><i class="fi-rs-shopping-cart mr-5"></i>Aggiungi
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AddToCartMixin from '@/mixins/CartMixin'

export default {
  name: 'ProductCardComponent',
  mixins: [AddToCartMixin],
  props: {
    product: {
      type: Object,
      required: true
    }
  },
}
</script>

<style scoped>

</style>
