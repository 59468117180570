<template>
  <div className="header-action-icon-2">
    <a className="mini-cart-icon" href="/#/cart">
      <img alt="Nest" src="/assets/imgs/theme/icons/icon-cart.svg"/>
      <span className="pro-count blue" v-if="cart.length > 0">{{ cart.length }}</span>
    </a>
    <a href="/#/cart"><span className="lable">Carrello</span></a>
    <div className="cart-dropdown-wrap cart-dropdown-hm2">
      <ul v-if="cart.length > 0">
        <SingleProduct v-for="(relation, key) in cart" :key="key" :product="relation.product"
                       :quantity="relation.quantity"></SingleProduct>
      </ul>
      <p v-else>Non hai prodotti nel cartello</p>
      <div className="shopping-cart-footer">
        <div className="shopping-cart-total" v-if="cart.length > 0">
          <h4>Totale <span>&euro; {{ cartTotal }}</span></h4>
        </div>
        <div className="shopping-cart-button" v-if="cart.length > 0">
          <a href="/#/cart">Carrello</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SingleProduct from '@/layout/ecommerce/header/cart/SingleProduct'
import CartMixin from '@/mixins/CartMixin'
import { useCartStore } from '@/stores/cart'

export default {
  name: 'CartComponent',
  components: { SingleProduct },
  mixins: [CartMixin],
  created () {
    let cart = useCartStore()
    cart.updateCart()
  }
}
</script>

<style scoped>

</style>
