import { defineStore } from 'pinia'
import { api } from '@/lib/axios'
//import { api } from '@/lib/axios'

export const useCartStore = defineStore('cart', {
  state: () => {
    return {
      cart: [],
    }
  },
  getters: {
    getCart (state) {
      return state.cart
    },
    hasProduct (state) {
      return (productId) => state.cart.find(item => item.product.id === productId)
    }
  },
  actions: {
    async updateCart () {
      if (localStorage.getItem('token') !== null) {
        let res = await api.get('/v1/cart')
        this.cart = res.data
      }
    },
    increaseQuantity (product, quantity = 1) {
      this.cart.forEach(async function (item) {
        if (item.product.id === product.id) {
          item.quantity = item.quantity + quantity
          await api.patch('/v1/carts/products/change-quantity/' + product.id, { quantity: item.quantity })
        }
      })
    },
    async addProduct (product, quantity) {
      const SELF = this

      const PRODUCT = {
        'quantity': quantity,
        'product': product,
      }

      try {
        await api.post('/v1/carts/product', PRODUCT).then(function (response) {
          SELF.cart.push(response.data)
        })
      } catch (error) {
        return error
      }
    },
    async removeProduct (product) {
      await api.delete('/v1/carts/products/' + product.id)
    },
    async changeQuantity (product, quantity) {
      await api.patch('/v1/carts/products/change-quantity/' + product.id, { quantity })
    },
    emptyCart () {
      this.cart = []
    },
    async checkoutUrl (stripe_id) {
      try {
        let res = await api.get('/v1/checkout-session/' + stripe_id)
        return res.data
      } catch (error) {
        return error
      }
    },
  }
})
