import { defineStore } from 'pinia'
import { api } from '@/lib/axios'

export const useCategoryStore = defineStore('category', {
  state: () => {
    return { categories: [] }
  },
  getters: {
    getCategories(state) {
      return state.categories
    },
  },
  actions: {
    async fetchCategories () {
      try {
        let res = await api.get('/v1/categories')
        this.categories = res.data
      } catch (error) {
        return error
      }
    },
  },
})
