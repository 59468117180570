<template>
  <form @submit="onSubmit">
    <input type="text" placeholder="Cerca..." v-model="search_value"/>
  </form>
</template>

<script>
import { useCategoryStore } from '@/stores/categories'

export default {
  name: 'SearchComponent',
  setup () {
    const categories = useCategoryStore()
    return { categories }
  },
  data () {
    return {
      search_value: ''
    }
  },
  methods: {
    onSubmit (e) {
      e.preventDefault()
      this.$router.push({
        name: 'search',
        params: {
          query: this.search_value
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
