<template>
  <div className="header-top header-top-ptb-1 d-none d-lg-block">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-xl-3 col-lg-4">
          <div className="header-info">
            <ul>
              <li><a href="/#/about">Su Negozietto</a></li>
              <li><a href="/#/account">Account</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TopBarComponent'
}
</script>

<style scoped>

</style>
