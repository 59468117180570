<template>
  <li>
    <div className="shopping-cart-img">
      <a :href="'/#/products/' + product.id"><img alt="Nest"
                                                  v-if="product.images.length > 0"
                                                  :src="product.images[0].url"/>
        <img alt="Nest"
             v-else
             src="/assets/imgs/shop/product-1-1.jpg"/>
      </a>
    </div>
    <div className="shopping-cart-title">
      <h4><a :href="'/#/products/' + product.id">{{ product.name }}</a></h4>
      <h4><span>{{ quantity }} × </span>{{ product.price_with_taxes }}</h4>
    </div>
    <div className="shopping-cart-delete">
      <a @click="$_cartMixinDelete(product)"><i className="fi-rs-cross-small"></i></a>
    </div>
  </li>
</template>

<script>
import CartMixin from '@/mixins/CartMixin'

export default {
  name: 'SingleProduct',
  mixins: [CartMixin],
  props: {
    product: {
      type: Object,
      required: true
    },
    quantity: {
      type: Number,
      required: true
    }
  },
}
</script>

<style scoped>

</style>
