<template>
  <div>
    <footer class="main">
      <section class="section-padding footer-mid">
        <div class="container pt-15 pb-20">
          <div class="row">
            <div class="col">
              <div class="widget-about font-md mb-md-3 mb-lg-3 mb-xl-0 wow animate__animated animate__fadeInUp"
                   data-wow-delay="0">
                <div class="logo mb-30">
                  <a href="index.html" class="mb-15"><img src="assets/logo.png" alt="logo"/></a>
                </div>

                <div style="display: flex">
                  <div style="margin-top: -15px;">
                    <a href="https://www.facebook.com/negozietto.help" target="_blank"><img style="max-width: 60px;"
                                                                                            src="/assets/imgs/theme/icons/icon-facebooknew.png"></a>
                  </div>
                  <div style="margin-right: 20px; margin-top: -5px">
                    <a href="https://instagram.com/negozietto.shop?igshid=YmMyMTA2M2Y=" target="_blank"><img
                        style="max-width: 40px;"
                        src="/assets/imgs/theme/icons/icon-instagram.png"></a>
                  </div>
                  <div style="margin-right: 20px; margin-top: 1px">
                    <a href="https://www.linkedin.com/company/negozietto/" target="_blank"><img style="max-width: 30px;"
                                                                                                src="/assets/imgs/theme/icons/icon-linkedin.png"></a>
                  </div>
                  <div style="margin-right: 20px; margin-top: 1px">
                    <a href="https://www.youtube.com/channel/UCYWJ590cDmJbnrKMLv_uiYA" target="_blank"><img style="max-width: 50px;"
                                                                                                src="/assets/imgs/theme/icons/icon-youtube.png"></a>
                  </div>
                </div>

              </div>
            </div>
            <div class="footer-link-widget col wow animate__animated animate__fadeInUp" data-wow-delay=".1s">
              <h4 class=" widget-title">Azienda</h4>
              <ul class="footer-list mb-sm-5 mb-md-0">
                <li><a href="/#/about">La Nostra Storia</a></li>
                <li><a href="/#/become-vendor">Apri il tuo Negozietto</a></li>
                <li><a href="/#/contact-us">Contattaci</a></li>
              </ul>
            </div>
            <div class="footer-link-widget col wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
              <h4 class="widget-title">Info</h4>
              <ul class="footer-list mb-sm-5 mb-md-0">
                <li><a href="/#/faq">Domande Frequenti</a></li>
                <li><a href="https://www.iubenda.com/privacy-policy/71392594">Privacy Policy</a></li>
                <li><a href="/#/terms-and-conditions">Termini &amp; Condizioni</a></li>
              </ul>
            </div>
            <div class="footer-link-widget widget-install-app col wow animate__animated animate__fadeInUp"
                 data-wow-delay=".5s">
              <p class="mb-20">Pagamenti Sicuri con:</p>
              <img class="" src="assets/imgs/theme/payment-method.png" alt=""/>
            </div>
          </div>
        </div>
      </section>
      <div class="container pb-30 wow animate__animated animate__fadeInUp" data-wow-delay="0">
        <div class="row align-items-center">
          <div class="col-12 mb-30">
            <div class="footer-bottom"></div>
          </div>
          <div>
            <p class="font-sm mb-0">&copy; 2022, <strong class="text-brand">Negozietto</strong><br/>All rights reserved
            </p>
          </div>
          <div class="col-xl-4 col-lg-6 text-center d-none d-xl-block">
          </div>
        </div>
      </div>
    </footer>
    <div id="preloader-active">
      <div className="preloader d-flex align-items-center justify-content-center">
        <div className="preloader-inner position-relative">
          <div className="text-center">
            <img src="assets/logo.png" alt=""/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterComponent'
}
</script>

<style scoped>

</style>
