<template>
  <EcommerceLayout>
    <template v-slot:content>
      <div class="page-content pt-150 pb-150">
        <div class="container">
          <div class="row">
            <div class="col-xl-8 col-lg-10 col-md-12 m-auto text-center">
              <p class="mb-20"><img src="assets/imgs/page/page-404.png" alt="" class="hover-up"/></p>
              <h1 class="display-2 mb-30">Pagina Non Trovata</h1>
              <p class="font-lg text-grey-700 mb-30">
                La pagina che stai cercando non è stata trovata.<br/>
                Torna alla <a href="/"> <span> Homepage</span></a> o <a href="/#/contact-us"><span>Contattaci</span></a>
              </p>
              <a class="btn btn-default submit-auto-width font-xs hover-up mt-30" href="/"><i
                  class="fi-rs-home mr-5"></i> Torna alla home</a>
            </div>
          </div>
        </div>
      </div>
    </template>
  </EcommerceLayout>
</template>

<script>
import EcommerceLayout from '@/layout/ecommerce/EcommerceLayout'

export default {
  name: 'PageNotFound',
  components: { EcommerceLayout }
}
</script>

<style scoped>

</style>
