import axios from 'axios'

const api = axios.create({ baseURL: process.env.VUE_APP_URL_API })
api.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`

api.interceptors.response.use((response) =>
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // eslint-disable-next-line implicit-arrow-linebreak
    response,
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response.status === 401) {
      localStorage.removeItem('current_user')
      localStorage.removeItem('token')
      // eslint-disable-next-line no-return-assign
      return window.location.href = '/#/login';
    }
    return Promise.reject(error);
  });


export { api }
