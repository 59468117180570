<template>
  <div>
    <div className="header-bottom header-bottom-bg-color sticky-bar">
      <div className="container">
        <div className="header-wrap header-space-between position-relative">
          <div className="logo logo-width-1 d-block d-lg-none">
            <a href="/"><img src="/assets/logo.png" alt="logo"/></a>
          </div>
          <div className="header-nav d-none d-lg-flex">
            <div className="main-menu main-menu-padding-1 main-menu-lh-2 d-none d-lg-block font-heading">
              <nav>
                <ul>
                  <li v-for="(category, index) in categories.getCategories" :key="index">
                    <router-link :to="'/categories/' + category.id">{{ category.name }}<i
                        v-if="category.subcategories.length > 0"
                        className="fi-rs-angle-down"></i></router-link>
                    <ul className="sub-menu" v-if="category.subcategories.length > 0">
                      <li v-for="(sub, index) in category.subcategories" :key="index"><a
                          @click="$router.push('/subcategories/' + sub.id)">{{ sub.name }}</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div className="header-action-icon-2 d-block d-lg-none">
            <div className="burger-icon burger-icon-white">
              <span className="burger-icon-top"></span>
              <span className="burger-icon-mid"></span>
              <span className="burger-icon-bottom"></span>
            </div>
          </div>
          <div className="header-action-right d-block d-lg-none">
            <div className="header-action-2">
              <div className="header-action-icon-2">
                <a className="mini-cart-icon" href="components/navbar/navbar#">
                  <img alt="Nest" src="assets/imgs/theme/icons/icon-cart.svg"/>
                  <span className="pro-count white" v-if="cart.length > 0">{{ cart.length }}</span>
                </a>
                <div className="cart-dropdown-wrap cart-dropdown-hm2">
                  <ul v-if="cart.length > 0">
                    <SingleProduct v-for="(relation, key) in cart" :key="key" :product="relation.product"
                                   :quantity="relation.quantity"></SingleProduct>
                  </ul>
                  <p v-else>Non hai prodotti nel carrello</p>
                  <div className="shopping-cart-footer" v-if="cart.length > 0">
                    <div className="shopping-cart-total">
                      <h4>Totale <span>&euro; {{ cartTotal }}</span></h4>
                    </div>
                    <div className="shopping-cart-button">
                      <a href="/#/cart">Vedi Carrello</a>
                      <a href="/#/checkout">Checkout</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="mobile-header-active mobile-header-wrapper-style">
      <div className="mobile-header-wrapper-inner">
        <div className="mobile-header-top">
          <div className="mobile-header-logo">
            <a href="/"><img src="/assets/logo.png" alt="logo"/></a>
          </div>
          <div className="mobile-menu-close close-style-wrap close-style-position-inherit">
            <button className="close-style search-close">
              <i className="icon-top"></i>
              <i className="icon-bottom"></i>
            </button>
          </div>
        </div>
        <div className="mobile-header-content-area">
          <div className="mobile-search search-style-3 mobile-header-border">
            <form action="#" @submit="onSubmit">
              <input type="text" placeholder="Cerca Prodotti…" v-model="search_value"/>
              <button type="submit"><i className="fi-rs-search"></i></button>
            </form>
          </div>
          <div className="mobile-menu-wrap mobile-header-border">
            <nav>
              <ul className="mobile-menu font-heading">
                <li className="menu-item-has-children" v-for="(category, index) in categories.getCategories"
                    :key="index">
                  <a :href="'/#/categories/' + category.id">{{ category.name }}</a>
                  <ul className="dropdown" v-if="category.subcategories.length > 0">
                    <li v-for="(sub, index) in category.subcategories" :key="index"><a
                        :href="'/#/subcategories/' + sub.id">{{ sub.name }}</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
          <div className="mobile-header-info-wrap">
            <AccountComponent></AccountComponent>
          </div>
          <div className="site-copyright">Copyright 2022 © Negozietto. Diritti riservati.</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useCategoryStore } from '@/stores/categories'
import CartMixin from '@/mixins/CartMixin'
import SingleProduct from '@/layout/ecommerce/header/cart/SingleProduct'
import AccountComponent from '@/layout/ecommerce/header/AccountComponent'

export default {
  name: 'NavbarComponent',
  mixins: [CartMixin],
  components: {
    AccountComponent,
    SingleProduct
  },
  data () {
    return {
      search_value: '',
    }
  },
  setup () {
    const categories = useCategoryStore()
    console.log(categories.getCategories)
    return { categories }
  },
  computed: {
    currentRoute () {
      return this.$route.name
    }
  },
  async beforeCreate () {
    let store = useCategoryStore()
    store.fetchCategories()
  },
  methods: {
    onSubmit (e) {
      e.preventDefault()
      this.$router.push({
        name: 'search',
        params: {
          query: this.search_value
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
