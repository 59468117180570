<template>
  <header className="header-area header-style-1 header-height-2">
    <TopBarComponent></TopBarComponent>
    <div className="header-middle header-middle-ptb-1 d-none d-lg-block">
      <div className="container">
        <div className="header-wrap">
          <div className="logo logo-width-1">
            <a href="/"><img src="/assets/logo.png" alt="logo"/></a>
          </div>
          <div className="header-right">
            <div className="search-style-2">
              <SearchComponent></SearchComponent>
            </div>
            <div className="header-action-right">
              <div className="header-action-2">
                <CartComponent></CartComponent>
                <AccountComponent></AccountComponent>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <NavbarComponent></NavbarComponent>
  </header>
</template>

<script>
import TopBarComponent from '@/layout/ecommerce/header/TopBarComponent'
import SearchComponent from '@/layout/ecommerce/header/SearchComponent'
import CartComponent from '@/layout/ecommerce/header/cart/CartComponent'
import AccountComponent from '@/layout/ecommerce/header/AccountComponent'
import NavbarComponent from '@/layout/ecommerce/header/NavbarComponent'

export default {
  name: 'HeaderComponent',
  components: { NavbarComponent, AccountComponent, CartComponent, SearchComponent, TopBarComponent }
}
</script>

<style scoped>

</style>
