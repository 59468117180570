<template>
  <div className="header-action-icon-2" v-if="!logged">
    <a href="/#/login">
      <img className="svgInject" alt="Nest" src="/assets/imgs/theme/icons/icon-user.svg"/>
    </a>
    <a href="/#/login"><span className="lable ml-0">Login</span></a>
  </div>
  <div className="header-action-icon-2" v-else>
    <a href="/#/account"><span className="lable ml-0">👋🏽 {{user.name}}!</span></a>
    <div className="cart-dropdown-wrap cart-dropdown-hm2 account-dropdown">
      <ul>
        <li>
          <a href="/#/account"><i className="fi fi-rs-user mr-10"></i>Mio Account</a>
        </li>
        <li>
          <a :href="shop_url" v-if="isShopActive"><i className="fi fi-rs-shop mr-10"></i>Vedi Negozio</a>
          <a href="/#/become-vendor" v-else><i className="fi fi-rs-shop mr-10"></i>Apri
            Negozio</a>
        </li>
        <li>
          <a @click="logout"><i className="fi fi-rs-sign-out mr-10"></i>Log out</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { useAuthStore } from '@/stores/auth'
import ShopMixin from '@/mixins/ShopMixin'

export default {
  name: 'AccountComponent',
  mixins: [ShopMixin],
  setup () {
    const authStore = useAuthStore()

    return { authStore }
  },
  created () {
    this.$_getShop();
  },
  computed: {
    logged: function () {
      return localStorage.getItem('token') !== null
    },
    user: function () {
      return JSON.parse(localStorage.getItem('current_user'))
    },
  },
  methods: {
    logout: function () {
      const authSotre = useAuthStore()
      const SELF = this
      authSotre.logout().then(function () {
        SELF.$router.push('/')
      })
    }
  }
}
</script>

<style scoped>

</style>
