import { defineStore } from 'pinia'
import { api } from '@/lib/axios'
import { toRaw } from 'vue'

export const useAuthStore = defineStore('auth', {
  state: () => {
    return {
      current_user: JSON.parse(localStorage.getItem('current_user')) || null,
    }
  },
  getters: {
    getUser (state) {
      return state.current_user
    },
  },
  actions: {
    resetPassword (data) {
      return new Promise((resolve, reject) => {
        api.post('/v1/user/reset-password', data)
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    register (data) {
      const SELF = this
      return new Promise((resolve, reject) => {
        api.post('/v1/user', data)
          .then((res) => {
            SELF.current_user = res.data.current_user
            localStorage.setItem('current_user', JSON.stringify(toRaw(this.current_user)))
            localStorage.setItem('token', res.data.token)
            api.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    login (data) {
      const SELF = this
      return new Promise((resolve, reject) => {
        api.post('/v1/users/login', data)
          .then((res) => {
            SELF.current_user = res.data.current_user
            localStorage.setItem('current_user', JSON.stringify(toRaw(this.current_user)))
            localStorage.setItem('token', res.data.token)
            api.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    async logout (data) {
      try {
        await api.post('/v1/users/logout', data)
        this.current_user = null
        localStorage.removeItem('current_user')
        localStorage.removeItem('token')
      } catch (error) {
        return error
      }
    },
    async updateData (data) {
      try {
        let res = await api.patch('/v1/user', data)
        this.current_user = res.data
        localStorage.setItem('current_user', JSON.stringify(toRaw(this.current_user)))
      } catch (error) {
        return error
      }
    },
    async getShop (data) {
      try {
        let res = await api.get('/v1/users/shop', data)
        return res.data
      } catch (error) {
        return error
      }
    },
    sendShopRequest (data) {
      const SELF = this
      return new Promise((resolve, reject) => {
        api.post('/v1/users/shop-request', data).then(function (response) {
          SELF.current_user.shop = response.data
          resolve(response)
        }).catch((error) => reject(error.response.data))
      })
    },
    contact (data) {
      return new Promise((resolve, reject) => {
        api.post('/v1/contact', data)
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
})
